import React from "react";

const HomeMain = () => (
  <section id="services">
    <h1>Welcome to Sprono LLC</h1>
    <p className="additional-text">
    At Sprono LLC, we specialize in providing comprehensive IT consulting and services tailored to your business needs. Our expertise lies in custom web and mobile application development, as well as native cloud solutions that drive efficiency and innovation.
    </p>
    <h2>Our Services:</h2>
    <p className="additional-text">
    <h3>Custom Web & Mobile Applications:</h3>
    Transform your ideas into reality with our robust web and mobile application development services.
    [<a href="/web-mobile-applications">Learn More</a>]
    </p>
    <p className="additional-text">
    <h3>Cloud Solutions:</h3>
    Embrace the future with our native cloud application development and consultation services.
    [<a href="/cloud-solutions">Learn More</a>]
    </p>
    <p className="additional-text">
    <h3>Content Management:</h3>
    Streamline your digital content with our advanced content management solutions.
    [<a href="/content-management">Learn More</a>]
    </p>
    <p className="additional-text">
    <h2>Why Choose Us?</h2>
    Expert Team: Our team of seasoned professionals is dedicated to delivering top-notch IT solutions.
    Innovative Solutions: We leverage the latest technologies to provide innovative and efficient solutions.
    Client-Centric Approach: We prioritize your needs and work closely with you to achieve your goals.
    </p>
  </section>
);

export default HomeMain;
