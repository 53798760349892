import React from "react";
import Navbar from "../../components/NavBar/Navbar.js";
import CloudSolution from "../../components/Services/CloudSolution.js";
import Footer from "../../components/Footer/Footer.js";
const CloudSolutionsPage = () => (
  <div>
    <Navbar />
    <CloudSolution />
    <Footer />
  </div>
);
export default CloudSolutionsPage;
