import React from "react";

const WebApplications = () => (
  <section id="services" className="scroll-adjust">
    <h1>Our Services</h1>
    <h2>Web / Mobile Development</h2>
    <p className="additional-text">
    We Provide custom web application development and mobile apps developments. 
    We use the cutting edge technologies such as serverless native cloud application development. 
    We also build and publish out own Mobile Apps in the App Store.
    </p>
    <h3>Custom Development:</h3>
    <p className="additional-text">
     We specialize in developing custom web and mobile applications that cater to your unique business needs. Our solutions are user-friendly, scalable, and secure.
   </p>
   <h3>Technology Stack:</h3>
   <p className="additional-text">
    We use the latest technologies to ensure your applications are robust and future-proof:<br />
    <b>Frontend:</b> React<br />
    <b>Backend:</b> Node.js<br />
    <b>Mobile:</b> Java, Flutter<br />
   </p>
   <h3>Development Process:</h3>
   <p className="additional-text">
    Our development process ensures transparency and efficiency at every stage:
    Requirement Analysis: Understanding your needs and goals.<br/>
    <b>Design:</b> Crafting user-centric designs.<br/>
    <b>Development:</b> Building scalable and secure applications.<br/>
    <b>Testing:</b> Rigorous testing to ensure quality.<br/>
    <b>Deployment:</b> Seamless deployment and launch.<br/>
    <b>Support:</b> Ongoing support and maintenance.<br/>
   </p>
  
    <h3>Apps we Published:</h3>    
    <p className="additional-text">
      Sprono PDF Cam Scanner <a href="https://play.google.com/store/apps/details?id=com.sprono.camscan" >view at Google Play Store</a><br/>
      Sprono QR and Barcode Scanner <a href="https://play.google.com/store/apps/details?id=com.sprono.qrscan" >view at Google Play Store</a><br/>
      Sprono PDF Reader & Editor <a href="https://play.google.com/store/apps/details?id=com.sprono.smartpdfreader" >view at Google Play Store</a><br/>
      Sprono Product Scanner <a href="https://play.google.com/store/apps/details?id=com.sprono.productscan" >view at Google Play Store</a><br/>
    </p>
  </section>
);

export default WebApplications;
