import React from "react";

const Services = () => (
  <section id="services" className="scroll-adjust">
    <h1>Our Services</h1>
    <p className="additional-text">
      We offer a comprehensive range of information technology services designed
      to meet the diverse needs of our clients. Our expertise includes custom
      web and mobile application development, as well as cutting-edge native
      cloud solutions. We are dedicated to delivering innovative, high-quality
      services that drive efficiency, scalability, and success for businesses of
      all sizes.
    </p>
    <div className="services-container">
      <div className="service-card">
        <h2>
          <a href="/cloud-solutions">Cloud Solutions</a>
        </h2>
        <p>
          We provide native cloud application development, and consultations
        </p>
      </div>
      <div className="service-card">
        <h2>
          <a href="/web-mobile-applications">Web/Mobile Applications</a>
        </h2>
        <p>
          We provide web and mobile application developments, and consultations
        </p>
      </div>
      <div className="service-card">
        <h2>
          <a href="/content-management">Content Management</a>
        </h2>
        <p>We provide web content management developments, and consultations</p>
      </div>
    </div>
  </section>
);

export default Services;
