import React, { useState } from "react";
import logo from "../../assets/logo.png";
import "./Navbar.css"; // Import the CSS file for styling

const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <img src={logo} alt="Sprono Logo" className="nav-logo" />
        <div
          className={`menu-icon ${menuActive ? "change" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <ul className={`nav-menu ${menuActive ? "active" : ""}`}>
          <li className="nav-item">
            <a href="/" className="nav-link">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="about-us" className="nav-link">
              About Us
            </a>
          </li>
          <li className="nav-item">
            <a href="services" className="nav-link">
              Our Services
            </a>
          </li>
          <li className="nav-item">
            <a href="contact-us" className="nav-link">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
