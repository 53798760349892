import React from "react";
import Navbar from "../../components/NavBar/Navbar";
import Services from "../../components/Services/Services";
import Footer from "../../components/Footer/Footer";
const ServicesPage = () => (
  <div>
    <Navbar />
    <Services />
    <Footer />
  </div>
);
export default ServicesPage;
