import React from "react";

const ContactUs = () => (
  <section id="contact-us" className="scroll-adjust">
    <h1>Contact Us</h1>
    <p className="additional-text">
    We'd love to hear from you! Whether you have questions about our services or need a consultation, our team is here to help.
    </p>
    <h2>Get in Touch:</h2>
    <p className="additional-text">
    <b>Email :</b> info@sprono.com<br/>
    <b>Address :</b> <br/>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.7852016593783!2d-77.51399672885096!3d38.98131060162795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b63f7e660d1101%3A0x5fbdd41015e294a7!2s22973%20Chertsey%20St%2C%20Ashburn%2C%20VA%2020148!5e0!3m2!1sen!2sus!4v1722829878220!5m2!1sen!2sus" width="800" height="450" style={{border:1,borderColor:"black" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </p>
    <p className="additional-text">

    </p>
  </section>
);

export default ContactUs;
