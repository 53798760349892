import React from "react";
import Navbar from "../components/NavBar/Navbar";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer/Footer";
const ContactUsPage = () => (
  <div>
    <Navbar />
    <ContactUs />
    <Footer />
  </div>
);
export default ContactUsPage;
