import React from "react";

const CloudSolution = () => (
  <section id="services" className="scroll-adjust">
    <h1>Our Services</h1>
    <h2>Cloud Solutions</h2>
    <p className="additional-text">
    We provide cloud native application development, and manage the infrastructure required to serve these applications.
    </p>
    <h3>Native Cloud Application Development:</h3>
    <p className="additional-text">
    Harness the power of the cloud with our native application development services. We design and deploy scalable, secure, and efficient cloud applications tailored to your business needs.
    </p>
    <h3>Consultation Services:</h3>
    <p className="additional-text">
    Our expert consultants provide comprehensive guidance on cloud adoption, migration, and optimization. We help you navigate the complexities of the cloud to achieve your business objectives.
    </p>
  </section>
);

export default CloudSolution;
