import React from "react";

const ContentManagement = () => (
  <section id="services" className="scroll-adjust">
    <h1>Our Services</h1>
    <h2>Content Management</h2>
    <p className="additional-text">
    We specialize in Tridion Web CMS, leveraging over 8 years of experience to serve 500 fortunate clients. Our expertise in Tridion has enabled us to deliver robust, scalable, and efficient content management solutions that meet the diverse needs of our clients.
    </p>
    <p className="additional-text">
    In addition to our Tridion expertise, we are expanding into other cutting-edge areas such as Headless CMS. We are proficient in platforms like Content Stack and Contentful, allowing us to offer flexible, modern solutions that cater to the evolving digital landscape.
    </p>
    <h3>CMS Development:</h3>
    <p className="additional-text">
     We provide tailored content management solutions that simplify your digital content operations. Our services include the development and customization of leading CMS platforms.
    </p>
  </section>
);

export default ContentManagement;
