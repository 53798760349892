import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/homepage";
import AboutUSPage from "./pages/aboutus";
import ServicesPage from "./pages/services/services";
import ContactUsPage from "./pages/contactus";
import ContentManagementPage from "./pages/services/contentmanagement";
import CloudSolutionsPage from "./pages/services/cloudsolutions";
import WebApplicationsPage from "./pages/services/webapplications";

import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about-us" element={<AboutUSPage />} />
        <Route path="services" element={<ServicesPage />} />
        <Route path="contact-us" element={<ContactUsPage />} />
        <Route path="content-management" element={<ContentManagementPage />} />
        <Route path="cloud-solutions" element={<CloudSolutionsPage />} />
        <Route
          path="web-mobile-applications"
          element={<WebApplicationsPage />}
        />
      </Routes>
    </Router>
  );
}

export default App;
