import React from "react";

const Hero = () => (
  <section id="home" className="hero-section scroll-adjust">
    <div className="hero-content">
      <div className="hero-card">
        <h1>IT Consulting & Services</h1>
        <p>
          At Sprono LLC, we provide various information technology services. It
          includes custom web / mobile application development, and native cloud
          solutions.
        </p>
      </div>
    </div>
  </section>
);

export default Hero;
