import React from "react";
import Navbar from "../components/NavBar/Navbar";
import Hero from "../components/Home/Hero";
import HomeMain from "../components/Home/HomeMain";
import Footer from "../components/Footer/Footer";
const HomePage = () => (
  <div>
    <Navbar />
    <Hero />
    <HomeMain />
    <Footer />
    {/* <AboutUs />
      <Services />
      <ContactUs /> */}
  </div>
);

export default HomePage;
