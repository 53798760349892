import React from "react";
import Navbar from "../../components/NavBar/Navbar.js";
import WebApplication from "../../components/Services/WebApplication.js";
import Footer from "../../components/Footer/Footer.js";
const WebApplicationsPage = () => (
  <div>
    <Navbar />
    <WebApplication />
    <Footer />
  </div>
);
export default WebApplicationsPage;
