import React from "react";
import Navbar from "../../components/NavBar/Navbar.js";
import ContentManagement from "../../components/Services/ContentManagement.js";
import Footer from "../../components/Footer/Footer.js";
const ContentManagementPage = () => (
  <div>
    <Navbar />
    <ContentManagement />
    <Footer />
  </div>
);
export default ContentManagementPage;
