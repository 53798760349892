import React from "react";

const AboutUS = () => (
  <section id="services" className="scroll-adjust">
    <h1>About Sprono LLC</h1>
    <p className="additional-text">
    At Sprono, we place our clients at the heart of everything we do. We are dedicated to understanding and exceeding their unique needs and expectations. By partnering closely with our clients, we take full ownership of the solution from its inception to final delivery, ensuring a seamless and successful journey.
    </p>
    <p className="additional-text">
    Our expertise lies in delivering exceptional custom web and mobile application development services. We pride ourselves on our ability to create innovative, high-quality solutions tailored to each client’s specific requirements. Our commitment to excellence and customer satisfaction drives us to go above and beyond in every project we undertake.
    </p>
    <h2>Our Mission:</h2> 
    <p className="additional-text">
    To empower businesses by delivering cutting-edge IT solutions that foster growth and drive success. We are committed to:
    <h3>Innovation:</h3> 
    Continuously exploring and integrating the latest technologies to provide our clients with state-of-the-art solutions that keep them ahead of the competition.
    <h3>Customization:</h3> 
    Tailoring our services to meet the unique needs and goals of each business, ensuring that our solutions are as effective and efficient as possible.
    <h3>Support:</h3> 
    Offering unparalleled customer service and technical support to help businesses navigate and overcome any IT challenges they may face.
    <h3>Partnership:</h3> 
    Building long-term relationships with our clients based on trust, transparency, and mutual success.
    <h3>Sustainability:</h3> Promoting sustainable practices in our operations and solutions to contribute to a greener, more responsible future.
    By focusing on these core principles, we aim to be the trusted IT partner that businesses rely on to achieve their full potential.
    </p>
    <h2>Our Vision:</h2> 
    <p className="additional-text">
    To be the premier global leader in IT consulting, renowned for our unwavering commitment to excellence and unparalleled client satisfaction. We aspire to set the industry standard by consistently delivering innovative, high-quality solutions that drive success and foster long-term partnerships with our clients.
    </p>
  </section>
);

export default AboutUS;
