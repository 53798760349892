import React from "react";
import Navbar from "../components/NavBar/Navbar";
// import AboutUs from "../components/AboutUs";
import AboutUs from "../components/AboutUs.js";
import Footer from "../components/Footer/Footer";
const AboutUSPage = () => (
  <div>
    <Navbar />
    <AboutUs />
    <Footer />
  </div>
);
export default AboutUSPage;
